import {
    SET_DONE_SEARCHING,
    SET_ERROR,
    SET_RESULT,
    useFoldersDispatch,
    useFoldersState,
    useFoldersTranslation,
} from "./FoldersContext";
import {damsFetch} from "../app/damsFetch";
import useDeepCompareEffect from "use-deep-compare-effect";
import {clientLog} from "../clientLog";
import {getFolderSearchParams} from "../getFolderSearchParams";
import {ADD_MESSAGE, useSnackbarDispatch} from "../snackbar/SnackbarContext";


/**
 * Custom hook for searching folders based on specified parameters.
 *
 * @param {string} query - The search query.
 * @param {string} sort - The sorting criteria.
 * @param {string} fl - The field list to display.
 * @param {number} start - The starting index for search results.
 * @param {number} rows - The number of rows to fetch.
 * @param {array} fq - The filter queries.
 * @param {string} status - The status of the folders.
 * @param {array} museums - The list of museums.
 * @param {boolean} expand - Flag to expand search.
 * @param {boolean} onlyMine - Flag to search only user's folders.
 * @param {boolean} onlyOthers - Flag to search others' folders.
 * @param {string} folderType - The type of folder to search.
 * @return {void}
 */
export const useFolderSearch = ({
                                    query,
                                    sort,
                                    fl,
                                    start = 0,
                                    rows = 100,
                                    fq = [],
                                    status = '(-trash)' || status,
                                    museums = [],
                                    expand = false,
                                    onlyMine = false,
                                    onlyOthers = false,
                                    folderType = "",
                                }) => {

    const {privateOnly, searching} = useFoldersState();

    const t = useFoldersTranslation();
    const searchDispatch = useFoldersDispatch();
    const snackbarDispatch = useSnackbarDispatch();

    const getFacet = (facet) => {
        if (!facet) {
            return 0;
        }

        let counter = {};
        for (let i = 0; i < facet.length; i += 2) {
            counter[facet[i]] = {
                count: facet[i + 1],
            };
        }
        return counter;
    };

    const getStats = (stats) => ({
        min: stats.min,
        max: stats.max,
    });

    const clearExistingResults = () => {
        searchDispatch({
            type: SET_RESULT,
            count: 0,
            models: [],
            facets: {},
            stats: {},
        });
    };

    const showErrorSnackbar = () => {
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t("snackbarFolderSearchErrorTitle", "Det oppstod en feil"),
                body: t('snackbarFolderSearchMessage', 'Innlasting av mapper feilet - kontakt support.'),
                type: "error",
            },
        });
    };

    useDeepCompareEffect(() => {
        if ((!onlyOthers && !onlyMine) && (!museums || museums?.length === 0)) {
            return;
        }

        if (!searching) {
            return;
        }

        clearExistingResults();

        const params = {
            q: query,
            sort: sort,
            fl: fl,
            expand: expand,
            start: start,
            rows: rows,
            documentType: `(Folder)`,
            museums: museums,
            onlyMine: onlyMine,
            onlyOthers: onlyOthers,
            status: privateOnly ? '(-trash private)' : status,
            facetField: "tags.reference.title,created_by_name", // Add additional filter fields here!
            facetLimit: 100,
            statsField: "created_at,updated_at,production_date,created_by_name",
        };

        const searchParams = getFolderSearchParams(folderType, params, fq);

        damsFetch(`/folders/search?${searchParams.toString()}`)
            .then((json) => {
                const {facetFields, statsFields} = json;
                searchDispatch({
                    type: SET_RESULT,
                    count: json['count'],
                    models: json['models'],
                    facets: {
                        tags: getFacet(facetFields['tagsReferenceTitle']),
                        createdByName: getFacet(facetFields['createdByName'])
                    },
                    stats: {
                        createdAt: getStats(statsFields['createdAt']),
                        updatedAt: getStats(statsFields['updatedAt']),
                        productionDate: getStats(statsFields['productionDate']),
                        createdByName: getStats(statsFields['createdByName'])
                    },
                });
                searchDispatch({type: SET_DONE_SEARCHING});
            })
            .catch((error) => {
                clientLog('error', error, 'useFolderSearch');
                showErrorSnackbar();
                searchDispatch({type: SET_ERROR});
            });
    }, [
        query,
        sort,
        fl,
        start,
        rows,
        fq,
        expand,
        searchDispatch,
        onlyMine,
        onlyOthers,
        museums,
        searching
    ]);
};
