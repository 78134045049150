import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {useDocumentTranslation} from "../documents/documentContext";
import Tab from "@mui/material/Tab";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {SelectMuseumDialog} from "../museum/SelectMuseum/SelectMuseumDialog";
import {ADMIN_LOCAL_ASSETS, RESET_ADMIN_LOCAL_ASSETS, useAppDispatch} from "../app/AppContext";
import {AdminAssetType} from "./AdminAssetType";
import {useMediaQuery} from "@mui/material";

/**
 * A component for administrating local data in DAMS.
 *
 * This component provides an interface for editing values that are stored in
 * DAMS' own database: people, subjects, and places. It displays a tabbed panel
 * with one tab for each type of data.
 *
 * @returns {ReactElement} The rendered component.
 */
export const AdminLocalAssets = () => {
    const [activeTab, setActiveTab] = useState('tab-person')
    const [museum, setMuseum] = useState({
        collectionId: -1
    });
    const t = useDocumentTranslation();
    const appDispatch = useAppDispatch();
    const smallScreen = useMediaQuery("(max-width: 768px)");

    /**
     * Method called when the user has selected the museum.
     * @param museum    The selected museum.
     */
    const handleSelectedMuseum = museum => {
        setMuseum(museum);
        appDispatch({
            type: ADMIN_LOCAL_ASSETS,
            museum: museum,
            assetType: 'person'
        });
    };

    /**
     * Handle tab change event.
     * @param {React.SyntheticEvent} event - The event.
     * @param {number} newValue - The new value of the selected tab.
     */
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        appDispatch({
            type: ADMIN_LOCAL_ASSETS,
            assetType: newValue.replace('tab-', '')
        });
    };

    /**
     * Hook used to remove the selected museum when exiting this component.
     */
    useEffect(() => {
        return () => {
            appDispatch({
                type: RESET_ADMIN_LOCAL_ASSETS
            });
        };
    }, [appDispatch]);

    return <>
        <SelectMuseumDialog selectedMuseumCallback={handleSelectedMuseum}
                            collectionId={museum?.collectionId}
                            projectContext={false}
                            dialogTitle={t('adminLocalAssets', 'Administrere lokale data')}
                            t={t}/>
        {Boolean(museum?.collectionId) && (
            <Paper sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                minWidth: smallScreen ? '100vw' : '90vw',
                maxWidth: '100vw',
                marginBottom: smallScreen ? '0px' : '32px',
                marginTop: smallScreen ? '0px' : '32px !important',
                marginLeft: smallScreen ? '0px' : '16px',
                marginRight: smallScreen ? '0px' : '16px',
                padding: '16px',
            }}>
                <Box sx={{marginBottom: '32px'}}>
                    <Typography variant={"h5"} gutterBottom>
                        {t('adminLocalAssets', 'Administrere lokale data')}
                    </Typography>
                    <Typography variant={"body"}
                                gutterBottom>
                        {t('adminLocalAssetsHelperText',
                            'Dette grensesnittet tillater redigering av verdier som ligger lagret ' +
                            'i DAMS sin egen databasen: personer, emneord og steder.')}
                    </Typography>
                </Box>
                <TabContext value={activeTab}>
                    <TabList onChange={handleTabChange}
                             aria-label={t("adminLocalAssets", "Adminstrere lokale data")}
                             TabIndicatorProps={{sx: {display: 'none'}}}
                             sx={{
                                 '& .MuiTabs-flexContainer': {
                                     flexWrap: 'wrap',
                                 },
                             }}>
                        <Tab label={t("adminLocalAssetsPersons", "Personer inkl. Fotograf/Produsent")}
                             value={'tab-person'}></Tab>
                        <Tab label={t("adminLocalAssetsSubjects", "Emneord")}
                             value={'tab-subject'}></Tab>
                        <Tab label={t("adminLocalAssetsPlaces", "Steder")}
                             value={'tab-place'}></Tab>
                    </TabList>
                    <TabPanel value={activeTab} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        overflowY: 'auto',
                        padding: 0
                    }}>
                        <AdminAssetType/>
                    </TabPanel>
                </TabContext>
            </Paper>)}</>;
};

