import {Dialog, DialogContent} from "@mui/material";
import {FormCreateFolder} from "../../folder/FormCreateFolder";
import React, {useState} from "react";
import {RESET_CREATE_FOLDER, useResultActionDispatch, useResultActionState} from "../ResultActionContext";
import {useAuthsState} from "../../auths/authsContext";
import {useFolderShareEmail} from "../../folder/useFolderShareEmail";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {damsFetch} from "../../app/damsFetch";
import {PromisePool} from "@supercharge/promise-pool";
import {clientLog} from "../../clientLog";
import {
    CREATE_FOLDER_STATE_RESET,
    FOLDER_CREATED,
    UPDATE_PROGRESS,
    useCreateFolderDispatch
} from "./CreateFolderContext";
import {saveNewTags} from "../../folder/tags/saveNewTags";

import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import {ProgressSnackbar} from "./ProgressSnackbar";


/**
 * The CreateFolderModalNew component renders a dialog, where the user can create a new folder,
 * and add the selected documents to the folder.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.t - The translation function.
 * @param {Array} props.selectedDocuments - The list of selected documents.
 * @param {function} props.onHideCallback - The callback function to call when the dialog is closed.
 *
 * @returns {JSX.Element} The CreateFolderModalNew component.
 */
const CreateFolderModalNew = ({t, selectedDocuments, onHideCallback}) => {
    const {showCreateFolder} = useResultActionState();

    const resultActionDispatch = useResultActionDispatch();
    const createFolderDispatch = useCreateFolderDispatch();

    const {userData} = useAuthsState();

    const [setEmailRecipients, sendEmailNotifications] = useFolderShareEmail();

    const [processing, setProcessing] = useState(false);

    /**
     * Handles the event when the dialog is closed.
     *
     * Resets the state of the create folder context, closes the create folder dialog,
     * and unchecks the selected documents.
     */
    const onHide = () => {
        createFolderDispatch({type: CREATE_FOLDER_STATE_RESET});
        closeCreateDialog();
        uncheckSelectedFiles();
    };

    /**
     * Closes the create folder dialog.
     *
     * Dispatches the {RESET_CREATE_FOLDER} action, which resets the state of the create folder context.
     */
    const closeCreateDialog = () => {
        resultActionDispatch({type: RESET_CREATE_FOLDER});
    };

    /**
     * Unchecks the selected files.
     *
     * Calls the onHideCallback function, which should uncheck the selected files in the search result.
     */
    const uncheckSelectedFiles = () => {
        onHideCallback();
    };

    /**
     *  Saving new tags to the DAMS database, if required, and appending them to the folderData object.
     * @param folderData
     * @returns {Promise<void>}
     */
    const appendTags = async (folderData) => {
        const {tags, collectionId} = folderData;
        if (!tags || tags.length === 0) {
            return;
        }

        const {results: newTags} = await saveNewTags(collectionId, tags);
        if (newTags) {
            folderData.tags = [
                ...folderData.tags.filter(t => !t.inputValue).map(t => ({reference: {...t}})),
                ...newTags
            ];
        } else if (!folderData.tags) {
            folderData.tags = newTags
        }
    };

    /**
     * Creates a new folder with the given metadata, and populates the e-mail recipients list.
     * @param {Object} folderData - A JSON-object describing the new folder.
     * @param {string} folderData.folderName - The name of the folder.
     * @param {Array<Object>} folderData.tags - The list of tags.
     * @param {Array<Object>} folderData.readShare - The list of read ACLs.
     * @param {Array<Object>} folderData.writeShare - The list of write ACLs.
     * @param {string} folderData.collectionId - The ID of the collection.
     * @returns {Promise<void>} - A promise that resolves when the folder is created.
     */
    const save = async (folderData) => {

        setProcessing(true);
        uncheckSelectedFiles();

        const {folderName} = folderData;
        await appendTags(folderData);

        // Create the necessary folder ACLs.
        const usersAcl = createUsersAcl();
        const emailsAcl = createFolderAcls(folderData);

        // Create the folder, and add the selected documents.
        try {
            const createdFolder = await createFolder(folderData, emailsAcl, usersAcl);
            createFolderDispatch({type: FOLDER_CREATED, created: true});
            closeCreateDialog();

            const {results} = await addDocumentsToFolder(createdFolder, selectedDocuments);

            // Log warning, number of failed documents
            const failedDocuments = [...results].filter(r => !r.success);
            clientLog('warn',
                `${failedDocuments.length} documents not added to the folder ${folderName}`,
                'createfoldermodal');

            await sendEmails(createdFolder['id'], emailsAcl, usersAcl);

            // Hide the processing dialog.
            setProcessing(false);

            // Reset state, and hide the create folder dialog.
            onHide();
        } catch (error) {
            clientLog('error', `failed to create the folder: ${error.toString()}`, 'createfoldermodal');
            setProcessing(false);
        }
    };

    /**
     * Send emails to the recipients specified in the folder ACLs.
     *
     * @param {number} folderId - The ID of the folder.
     * @param {object} emailsAcl - The folder ACLs for external users.
     * @param {object} usersAcl - The folder ACLs for users.
     *
     * @returns {Promise<void>}
     */
    const sendEmails = async (folderId, emailsAcl, usersAcl) => {
        return new Promise(async (resolve) => {

            // Set the recipients that will receive email.
            setEmailRecipients(null, emailsAcl, usersAcl);

            const res = await sendEmailNotifications(folderId); // NOSONAR
            const {results} = res;
            if (results.length > 0) {
                const successCount = [...results].filter(r => r.success)?.length || 0;
                const failedCount = results.length - successCount;

                clientLog('info',
                    `failed e-mails: ${failedCount} of total ${results.length}`,
                    'createfoldermodal');

                clientLog('info',
                    `successful e-mails: ${successCount} of total ${results.length}`,
                    'createfoldermodal');
            }
            resolve();
        });
    };


    /**
     * Creates a new folder.
     *
     * @param {object} folderJson - A JSON-object describing the new folder.
     * @param {object} emailsAcl - The folder ACLs for external users.
     * @param {object} usersAcl - The folder ACLs for users.
     *
     * @returns {Promise<Response>}
     */
    const createFolder = async (folderJson, emailsAcl, usersAcl) => {
        const {collectionId, folderAvailability, folderName, tags} = folderJson;
        const folder = {
            collectionId: collectionId,
            locale: "no",
            status: folderAvailability,
            title: folderName,
            description: "",
            content: {
                usersAcl: usersAcl,
                tags: tags
            },
            acls: emailsAcl,
        };
        const options = {
            method: 'POST',
            body: JSON.stringify(decamelizeKeysDeep(folder))
        };
        return await damsFetch('/folders', options);
    };

    /**
     * Adds a list of documents to a folder.
     *
     * @param {object} folder - The folder object.
     * @param {object[]} documents - The list of documents to add.
     *
     * @returns {Promise<void>}
     */
    const addDocumentsToFolder = async (folder, documents) => {
        return await PromisePool
            .withConcurrency(4)
            .for(documents)
            .onTaskFinished((document, pool) => {
                const percentage = parseInt(pool.processedPercentage());
                createFolderDispatch({
                    type: UPDATE_PROGRESS,
                    percentage: percentage
                });
            })
            .process(async (document) => {
                return await addDocumentToFolder(folder, document);
            });
    };

    /**
     * Adds the specified document to the specified folder, and returns the results:
     *   {
     *     results: [
     *          { id: <doc id>, success: true | false },
     *          ...
     *      },
     *      errors: [
     *          { id: <doc id>, success: false },
     *          ...
     *      ]
     *   }
     * @param folder JSON   A JSON-object, holding the folder information.
     * @param document JSON A JSON-object, holding the document information.
     * @returns {Promise<{success: boolean, id}>}
     */
    const addDocumentToFolder = async (folder, document) => {
        const {id} = folder;
        const {id: docId} = document;

        // Get document
        try {
            const doc = await damsFetch(`/documents/${docId}`);
            if (doc) {
                // Keep existing folder refs. for the document.
                const existingFolderIds = doc['folderIds'];

                // Update folder refs.
                const folderIds = existingFolderIds ? [...existingFolderIds, id] : [id];

                const body = JSON.stringify(
                    decamelizeKeysDeep({
                        ...doc,
                        content: {
                            ...doc['content'],
                        },
                        folderIds: folderIds
                    })
                );
                await damsFetch(`/documents`, {
                    method: "POST",
                    body: body
                });
                return {id: docId, success: true};
            }
        } catch (error) {
            clientLog('error',
                `add document to folder failed: ${error.toString()}`,
                'createfoldermodal');
            return {id: docId, success: false};
        }
    };

    /**
     * Creates a usersAcl for a folder, containing the current user as the folder owner.
     *
     * @returns {object[]} - An array of objects, representing the usersAcl.
     */
    const createUsersAcl = () => {
        return [
            {
                referenceType: "user_acl",
                _action: "put",
                reference: {
                    locale: "no",
                    _action: "put",
                    title: userData.uniqueId,
                    status: "published",
                },
            },
        ];
    };

    /**
     * Creates an array of objects, representing the access control list for a folder,
     * given a folderJson object.
     *
     * The returned array contains objects with the following properties:
     * - access (string): The type of access, either "read" or "write".
     * - email (string): The email address of the user with access to the folder.
     * - expires_at (number): The timestamp for when the access expires.
     *
     * @param {object} folderJson - The folderJson object, containing the readShare and writeShare fields.
     * @returns {object[]} - An array of objects, representing the access control list for the folder.
     */
    const createFolderAcls = (folderJson) => {
        return [
            ...folderJson.readShare.map((readAcl) => ({
                access: "read",
                email: readAcl.email,
                expires_at: readAcl.expiresAt,
            })),
            ...folderJson.writeShare.map((writeAcl) => ({
                access: "write",
                email: writeAcl.email,
                expires_at: writeAcl.expiresAt,
            })),
        ];
    };

    return <>
        <Dialog open={showCreateFolder} onClose={onHide} PaperProps={{
            sx: {
                width: '800px',
                minWidth: '800px',
                height: '640px',
                minHeight: '640px'
            }
        }}>
            <DialogTitle>
                {t('dlgCreateNewFolderTitle', 'Opprett mappe og legg til filer')}
            </DialogTitle>
            <DialogContent sx={{paddingTop: '16px !important'}}>
                <FormCreateFolder onSave={save} onHide={onHide}/>
            </DialogContent>
        </Dialog>
        {processing && <ProgressSnackbar t={t}/>}
    </>
};

CreateFolderModalNew.propTypes = {
    "t": PropTypes.func.isRequired,
    "selectedDocuments": PropTypes.any.isRequired,
    "onHideCallback": PropTypes.func.isRequired
};

export {CreateFolderModalNew};
