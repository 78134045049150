import React, {useEffect, useState} from "react";
import {Stack, Toolbar, useMediaQuery, useTheme} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useFoldersTranslation} from "./FoldersContext";
import {useAuthsState} from "../auths/authsContext";
import {useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

/**
 * Renders a toolbar with a title, an optional edit folder button, an optional "back" button,
 * a search field, and a list of tags. The edit folder button is only shown if the user is
 * not an external user and the editFolderButton prop is true. The search field is only
 * shown if the user is not an external user.
 *
 * @param {Object} props The component props.
 * @param {string} props.folderTitle The title of the folder.
 * @param {boolean} [props.editFolderButton=false] Whether to show the edit folder button.
 * @param {function} props.searchOnEnterCallback A callback to call when the user presses
 *    enter in the search field.
 * @param {string} props.inputValue The current value of the search field.
 * @param {function} props.setInputValue A function to call to set the value of the search
 *    field.
 * @param {Object[]} props.tags The tags associated with the folder.
 * @returns {ReactElement} The rendered component.
 */
export const FolderTitleToolbar = ({
                                       tags,
                                       folderTitle,
                                       editFolderButton,
                                       searchOnEnterCallback,
                                       inputValue,
                                       setInputValue,
                                   }) => {
    const {userData} = useAuthsState();
    const navigate = useNavigate();
    const theme = useTheme();
    const t = useFoldersTranslation();

    const [externalUser, setExternalUser] = useState(true);
    const [folderTags, setFolderTags] = useState([]);

    const smallScreen = useMediaQuery("(max-width: 768px)");

    /**
     * Hook used to keep the folder tags in sync.
     */
    useEffect(() => {
        setFolderTags(tags);
    }, [tags]);

    useEffect(() => {
        if (userData['appAccess']?.museums.length > 0) {
            setExternalUser(false);
        }
    }, [userData, externalUser]);


    /**
     * Renders a back arrow if the user is not an external user.
     * @returns {ReactElement} The rendered component.
     */
    const renderBackArrow = () => {
        if (externalUser) {
            return <></>;
        }

        return <IconButton
            sx={{color: "black"}}
            onClick={() => navigate(-1)}
            size="large"
        >
            <ArrowBackIcon sx={{height: 30, width: 30}}/>
        </IconButton>;
    };

    /**
     * Renders the folder tags as chips.
     * @returns {ReactElement} The rendered component.
     */
    const renderTags = () => {
        if (!folderTags || folderTags?.length === 0) {
            return <></>;
        }

        return <Box>{folderTags.map(t => {
            const title = t?.reference?.title || t?.title;
            return <Chip key={title} label={title} color={"secondary"} sx={{marginRight: '8px'}}/>
        })}</Box>;
    };

    return <Toolbar sx={{
        padding: 0,
        marginLeft: smallScreen ? theme.spacing(1) : theme.spacing(2),
        marginTop: smallScreen ? '56px' : theme.spacing(4),
        marginBottom: smallScreen ? '48px' : theme.spacing(1),
    }}>
        <Grid container
              direction={"column"}
              sx={{
                  marginBottom: '16px'
              }}>
            <Grid>
                <Stack direction={'row'}
                       alignItems={'center'}
                       sx={{
                           maxWidth: smallScreen ? '768px' : '900px',
                           flexWrap: 'wrap'
                       }}>
                    {renderBackArrow()}
                    <Typography variant={"h5"}
                                noWrap
                                sx={{
                                    width: '260px',
                                    maxWidth: '260px',
                                    marginRight: '16px'
                                }}>
                        {folderTitle}
                    </Typography>
                    {!externalUser && editFolderButton}
                    <TextField
                        sx={{
                            maxWidth: '400px',
                            width: '400px',
                            marginLeft: smallScreen ? '0px' : '32px',
                            marginBottom: smallScreen ? '8px' : '0px'
                        }}
                        id="dams-search"
                        label={t("searchInFolder", "Søk i mappe")}
                        type="search"
                        variant="filled"
                        onKeyDown={searchOnEnterCallback}
                        value={inputValue}
                        fullWidth={true}
                        onChange={(event) => setInputValue(event.target.value)}>
                    </TextField>
                </Stack>
            </Grid>
            <Grid>
                {renderTags()}
            </Grid>
        </Grid>
    </Toolbar>;
};
