import {useCallback, useEffect, useMemo, useState} from "react";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import Box from "@mui/material/Box";
import {useMuseumNameFromCollectionId} from "../museum/useMuseumNameFromCollectionId";
import {clientLog} from "../clientLog";
import {saveRow} from "./datatable/saveRow";
import {getTableTranslations} from "../table-base/getTableTranslations";
import {showBanner} from "./datatable/showBanner";
import {fetchLocalAsset} from "./datatable/fetchLocalAsset";
import {useDocumentTranslation} from "../documents/documentContext";
import {getTableColumnsBase} from "../table-base/getTableColumnsBase";
import {useAppState} from "../app/AppContext";
import {ConfirmDeleteDialog} from "./ConfirmDeleteDialog";
import {damsFetch} from "../app/damsFetch";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useDeepCompareEffect from "use-deep-compare-effect";
import {KulturNavCheckIcon} from "./datatable/KulturNavCheckIcon";
import {getTableBaseConfig} from "./datatable/getTableBaseConfig";

/**
 * A component for administrating local data in DAMS.
 *
 * This component provides an interface for editing values that are stored in
 * DAMS' own database: people, subjects, and places. It displays a tabbed panel
 * with one tab for each type of data.
 *
 * @returns {ReactElement} The rendered component.
 */
export const AdminAssetType = () => {
    const {adminLocalAssets} = useAppState();
    const [data, setData] = useState([]);
    const [museum, setMuseum] = useState();
    const [saved, setSaved] = useState(0);
    const [deleteId, setDeleteId] = useState(undefined);
    const [validationErrors, setValidationErrors] = useState([]);
    const [assetType, setAssetType] = useState();

    const [getMuseumName] = useMuseumNameFromCollectionId();
    const t = useDocumentTranslation();

    /**
     * Loads data for the currently selected museum and asset type
     * and updates the state.
     */
    const loadData = () => {
        fetchLocalAsset("", museum, assetType).then((data) => {
            setData(data);
        });
    };

    /**
     * Opens the confirm delete dialog for the asset with the given ID.
     * @param {number} id The ID of the asset to delete.
     */
    const confirmDelete = (id) => {
        setDeleteId(id);
    };

    /**
     * Closes the confirm delete dialog and reloads the data.
     */
    const closeConfirmDeleteDialog = () => {
        setDeleteId(undefined);
        loadData();
    };

    /**
     * Deletes the asset with the given ID, and reloads the data.
     * This will close the confirm delete dialog if it is open.
     */
    const deleteAsset = () => {
        if (typeof deleteId === "undefined") {
            return;
        }
        damsFetch(`/documents/${deleteId}`, {
            method: "DELETE",
        })
            .then(closeConfirmDeleteDialog)
            .catch(e => {
                clientLog("error", "error deleting local asset: " + e, "adminlocalasset");
            });
    };

    /**
     * Saves the given data to the DAMS database.
     *
     * @param {{values: Object}} props
     * @param {Object} props.values The data to save.
     *
     * Exits the edit mode, saves the data, and shows a success banner.
     * If the save fails, it shows an error banner and logs the error
     * to the client log.
     */
    const saveData = async ({_, values}) => {
        exitEdit();
        try {
            await saveRow(assetType, values);
            showBanner("success", setSaved);
        } catch (e) {
            showBanner("error", setSaved);
            clientLog("error", "error saving local asset: " + e, "adminlocalasset");
        }
    };

    /**
     * Exits edit mode.
     *
     * This function is called when the user is done editing a row.
     * It tells the table to exit edit mode and stop showing the
     * editing row.
     */
    const exitEdit = () => {
        table.setEditingRow(null);
    };

    const SourceCell = useCallback(({row}) => (
        <KulturNavCheckIcon value={row.original?.sourceId}/>
    ), []);

    const columns = useMemo(() => {
        const titleColumn = {
            'id': 'title',
            'accessorKey': assetType === 'person' ? 'content.name' : 'title',
            'header': t("columnName", "Navn"),
            'muiEditTextFieldProps': ({cell}) => ({
                'type': "text",
                'required': true,
                'error': !!validationErrors?.[cell.id],
                'helperText': validationErrors?.[cell.id],
                'onBlur': () => {
                    setValidationErrors({
                        ...validationErrors,
                        [cell.id]: null,
                    });
                },
            }),
        };

        const sourceColumn = {
            'id': 'source',
            'accessorKey': "content.source_id",
            'header': t("assetFromKulturNav", "KulturNav"),
            'enableEditing': false,
            'enableSorting': false,
            Cell: SourceCell,
            Edit: () => null,
        };

        const museumColumn = {
            'id': 'museum',
            'header': t("columnMuseum", "Museum"),
            'enableEditing': false,
            'accessorFn': (row) => getMuseumName(row.collectionId)
        };

        const orphanColumn = {
            'id': 'orphan',
            'accessorKey': "orphan",
            'enableEditing': false,
            'header': t("columnOrphan", "Orphan"),
            'enableSorting': false,
            'accessorFn': (row) => (row.orphan ? t("yes", "Ja") : t("no", "Nei")),
        };

        const baseColumns = getTableColumnsBase();
        if (assetType === 'place' || assetType === 'subject') {
            return [...baseColumns, titleColumn, museumColumn, orphanColumn, sourceColumn];
        } else if (!assetType || assetType === 'person') {
            return [
                ...baseColumns,
                titleColumn,
                {
                    'id': 'yearOfBirth',
                    'accessorKey': 'content.yearOfBirth',
                    'header': t("columnYearOfBirth", "Fødselsår"),
                    'muiEditTextFieldProps': ({cell}) => ({
                        'type': "text",
                        'required': false,
                        'error': !!validationErrors?.[cell.id],
                        'helperText': validationErrors?.[cell.id],
                        'onBlur': () => {
                            setValidationErrors({
                                ...validationErrors,
                                [cell.id]: null,
                            });
                        },
                    }),
                }, {
                    'id': 'yearOfDeath',
                    'accessorKey': 'content.yearOfDeath',
                    'header': t("columnYearOfDeath", "Dødsår"),
                    'muiEditTextFieldProps': ({cell}) => ({
                        'type': "text",
                        'required': true,
                        'error': !!validationErrors?.[cell.id],
                        'helperText': validationErrors?.[cell.id],
                        'onBlur': () => {
                            setValidationErrors({
                                ...validationErrors,
                                [cell.id]: null,
                            });
                        },
                    }),
                },
                museumColumn,
                orphanColumn,
                sourceColumn
            ];
        }

    }, [assetType, getMuseumName, t, validationErrors, SourceCell]);

    const translations = useMemo(() => getTableTranslations(t), [t]);

    const tableConfig = {
        ...getTableBaseConfig(columns, data, translations, saveData, exitEdit),
        'muiToolbarAlertBannerProps': saved
            ? {
                'color': "success",
                'children': t("tableAlertSaved", "Lagret"),
            }
            : {
                'color': "error",
                'children': t("tableAlertError", "Lagring feilet"),
            },
        'state': {
            'showAlertBanner': saved,
        },
        'muiTablePaperProps': {
            'sx': {
                'display': 'flex',
                'flexDirection': 'column',
            }
        },
        renderRowActions: ({row, table}) => {
            const {uniqueId, orphan} = row.original;
            return (
                <Box sx={{display: 'flex', gap: '0.5rem'}}>
                    {orphan ? (
                        <Tooltip title={t('tooltipDelete', 'Slett')}>
                            <IconButton color="error" onClick={() => confirmDelete(uniqueId)}>
                                <DeleteIcon/>
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title={t('tooltipEdit', 'Rediger')}>
                            <IconButton onClick={() => table.setEditingRow(row)}>
                                <EditIcon/>
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            );
        },
    };

    const table = useMaterialReactTable(tableConfig);

    /**
     * Hook used to set the museum selected by the user, and cleaning up when leaving this part of the application.
     */
    useDeepCompareEffect(() => {
        if (!adminLocalAssets || !adminLocalAssets.museum) {
            return;
        }
        setMuseum(adminLocalAssets.museum);

        if (!adminLocalAssets || !adminLocalAssets.assetType) {
            return;
        }
        setAssetType(adminLocalAssets.assetType);

        return () => {
            setMuseum(undefined);
            setAssetType(undefined);
        }
    }, [adminLocalAssets]);

    /**
     * Hook used to load the data, once the user has selected a museum.
     */
    useEffect(() => {
        if (!museum || !assetType) {
            return;
        }
        loadData();
    }, [museum, assetType]);

    return <>
        <Box sx={{padding: '0px', display: 'flex', flexDirection: 'column', overflow: 'auto'}}>
            <MaterialReactTable table={table}/>
        </Box>
        <ConfirmDeleteDialog
            open={typeof (deleteId) !== 'undefined'}
            onClose={() => setDeleteId(undefined)}
            onConfirm={deleteAsset}
            t={t}
        />
    </>;
};